const AOS = require('aos');
/*const SmoothScroll = require('smooth-scroll');

// Smooth scroll behavior for the menu links
new SmoothScroll('a[href*="#"]', {
  header: '[data-scroll-header]',
  easing: 'easeInOutCubic',
  speed: 2000,
  topOnEmptyHash: true,
  updateURL: true,
});


// Listener for applying a class to the navbar on scroll
window.addEventListener('scroll', event => {
  const menu = document.getElementById('menu'); 
  const scrollPosY = window.pageYOffset | document.body.scrollTop;

  if(scrollPosY > 50) {
    menu.classList.add(`scrolling`);
  } else if(scrollPosY <= 100) {
    menu.classList.remove(`scrolling`);
  }
});*/


// Custom JS
AOS.init({disable: 'phone', offset: 50, duration: 600, easing: 'ease-in'});

const submit = document.querySelectorAll(".form form");

submit.forEach(e=>{
  e.addEventListener('submit', (evt) => {
    evt.preventDefault();
    const email = e.querySelector('input[type=email]');
    const msgEle = e.parentNode.querySelector('.msg');
    const urls = {
      production: 'https://api.airtable.com/v0/applANVGS2kM038l3/Table%201',
    }

    if (validateEmail(email, msgEle)) {
      fetch(urls['production'], {
        body: "{\"records\": [{ \"fields\":{\"Email\": \"" + email.value + "\"}}]}",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer keyCaAELBWRJi4bI9"
        },
        method: "POST"
      }).then((response) => {
        //console.log("Response");
        //console.log(response);
        if (response.ok == true) {
          msgEle.innerHTML = "<p>Email added.</p>";
          msgEle.classList.add("success", "active");
          const s = setTimeout(() => {
            if (msgEle.classList.contains("success")) {
              msgEle.classList.remove("success", "active");
            }
            clearTimeout(s);
          }, 3000);
        }
      })
    }
  })
});

function validateEmail(inputText, msgEle) {
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  //console.log(msgEle);
  if (inputText.value.match(mailformat)) {
    //document.form1.text1.focus();
    return true;
  }
  else if (inputText.value) {
    msgEle.innerHTML = "<p>Email empty.</p>";
    msgEle.classList.add("error", "active");
    const e = setTimeout(() => {
      if (msgEle.classList.contains("error")) {
        msgEle.classList.remove("error", "active");
      }
      clearTimeout(e);
    }, 3000);
    return false;
  }
  else {
    msgEle.innerHTML = "<p>Email address invalid.</p>";
    msgEle.classList.add("error", "active");
    const e = setTimeout(() => {
      if (msgEle.classList.contains("error")) {
        msgEle.classList.remove("error", "active");
      }
      clearTimeout(e);
    }, 3000);
    return false;
  }
}
